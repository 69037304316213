.swiper {
  width: 100%;
  padding-bottom: 40px !important;

  .swiper-slide {
    text-align: center;
    overflow: visible;
    font-size: 18px;
    display: block;
    object-fit: cover;
    align-items: center;
  }

  .slide-title {
    font-family: "tenorsans";
    font-size: 20px;
    width: 100%;
    margin-top: 24px;
    display: block;
    color: theme("colors.xdarkgrey");
    text-align: left;
  }

  .slide-description {
    font-family: "inter";
    margin-top: 8px;
    width: 100%;
    display: block;
    text-align: left;
    line-height: 1.75rem;
    font-size: 14px;
    color: #878787;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-scrollbar {
    height: 2px !important;
    background: #eaeaea;
    width: 100% !important;
    left: 0 !important;

    .swiper-scrollbar-drag {
      background: #994c3a;
    }
  }
}

.swiper-navigation-button-left {
  position: absolute;
  left: 1rem;
  top: 40%;
  transform: translateY(-40%);
  cursor: pointer;
  z-index: 1000;
  user-select: none;
  svg path {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adding box shadow */
    user-select: none;
  }
}

.swiper-navigation-button-right {
  position: absolute;
  right: 1rem;
  top: 42%;
  transform: translateY(-42%);
  user-select: none;
  z-index: 1000;
  cursor: pointer;
  svg path {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adding box shadow */
    user-select: none;
  }
}

.swiper-hero-button-prev,
.swiper-hero-button-next {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.swiper-exterior-button-prev,
.swiper-exterior-button-next,
.swiper-garden-button-prev,
.swiper-garden-button-next {
  top: 45% !important;
  transform: translateY(-45%) !important;
}
