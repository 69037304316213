@font-face {
  font-family: 'tenorsans';
  src: url('../assets/fonts/tenorsans/TenorSans.woff2') format('woff2'),
       url('../assets/fonts/tenorsans/TenorSans.woff') format('woff');
}

@font-face {
  font-family: 'inter';
  src: url('../assets/fonts/inter/Inter-Regular.woff2') format('woff2'),
       url('../assets/fonts/inter/Inter-Regular.woff') format('woff');
}
