.c-button {
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  //   gap: 20px;

  border-radius: 56px;
  background: #244800;

  color: #fff;
  // max-width: fit-content;
  width: fit-content;
  span {
    display: block;
    width: fit-content;
    white-space: nowrap;
  }
}
