@import "./settings/fonts.scss";
@import "./components/lightboxslider.scss";
@import "./components/swiper.scss";
@import "./components/table.scss";
@import "./components/button.scss";
@import "./components/modal.scss";
@import "./components/map.scss";
@import "./elements/heading.scss";
@import "./elements/select.scss";
@import "./elements/reset.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
