[lightbox-toggle] {
  cursor: zoom-in;

  &:after {
    position: absolute;
    content: url('data:image/svg+xml; utf8, ');
    height: 32px;
    width: 32px;
    bottom: 0;
    right: 0;
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.2s;
  }

  &:hover {
    &:after {
      opacity: 1;
      filter: drop-shadow(2px 4px 6px black);
    }
  }
}

.c-lightbox {
  $c: &;

  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  pointer-events: none;
  position: fixed;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;

  &.open {
    opacity: 1;
    pointer-events: all;
  }

  #{$c}__container {
    width: 100%;
    height: 100%;
  }

  #{$c}__close {
    z-index: 999999;
    position: absolute;
    cursor: pointer;
    top: 1vh;
    right: 1vw;
    font-size: 30px;
    padding: 20px;
    color: lightgrey;

    &:hover {
      color: white;
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .swiper-pagination {
    color: white;
  }

.swiper-button-next{
  position: absolute;
  top: 50%;
  right: 5%;
   z-index: 999999;
   padding: 8px;
   cursor: pointer;
}

.swiper-button-prev{
  position: absolute;
  top: 50%;
  left: 5%;
   z-index: 999999;
   cursor: pointer;
   padding: 8px;
}

  .swiper-button-next,
  .swiper-button-prev {
    color: lightgray;

    &:hover {
      color: white;
    }

    &:after {
      font-size: 30px;
    }
  }

  #{$c}__image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 75%;
    width: 75%;
    text-align: center;
    cursor: zoom-in;

    img {
      width: auto;
      height: auto;
      max-height: 90vh;
      max-width: 90vw;
    }
  }
}