h1, h2, h3{
    font-family:'tenorsans', sans-serif;
}

h1{
    font-size: 2.4rem;
}

h2{
    font-size: 2rem;
}

h3{
    font-size: 1rem;
}