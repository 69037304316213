/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
}

/* Modal Content */
.modal-content {
  margin: auto;
  display: block;
  width: 800px;
  max-width: 80%;
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  z-index: 2;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
